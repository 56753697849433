import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from 'src/layouts/MainLayout/store/actions';
import { resetPersistentData } from '../views/TripView/store/actions';
import { resetSettings } from '../views/TripSettingsView/store/actions';
import userManager from '../utils/userManager';
import { selectIsAuthenticated } from '../store/selectors';

export function useAuth() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation('topBar');
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const handleLogout = async () => {
    dispatch(resetPersistentData());
    dispatch(resetSettings());
    userManager.signoutRedirect();
  };

  const handleLogIn = () => {
    // @ts-ignore
    userManager.storeUser(null);
    userManager.clearStaleState();
    userManager.signinRedirect({
      ui_locales: i18n.language,
      extraQueryParams: {
        lang: i18n.language
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actions.getUser.request());
    } else {
      userManager.removeUser();
      userManager.clearStaleState();
    }
  }, [dispatch, isAuthenticated]);

  return {
    handleLogIn,
    handleLogout
  };
}
